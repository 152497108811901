import Validatable from "../common/Validatable";
import { observable, reaction } from "mobx";
import dayjs from "dayjs";
import { ManufactureItemOption } from "./ManufactureItemOption";
import Helper from "../../../module/Helper";
import { ItemVersion, UnitType, storageTempKeyType } from "@celemicsbisw/manufacture-item-structure";

const probeReg = /^([0-9][0-9](0[1-9]|1[0-2]))|(CLMX)PD[0-9]{3}-V([0-9]|[0-9][0-9])$/;

export interface ManufactureItemProps {
    itemName?: string;
    unit?: 'rxn' | 'ml' | '';
    storageTemp?: '-80℃' | '-20℃' | '4℃' | 'RT' | 'RT (-80℃)' | storageTempKeyType;
    shippingCondition?: 'RT' | 'Ice pack' | 'Dry ice' | '4~8℃' | '';
    no?: number;
    numberPerQty?: number;
    validateList?: string[];
    optionReadonly?: boolean;
    itemOptions?: ManufactureItemOption;
    version?: ItemVersion;
    // 커스텀 아이템인지 구분용: 커스텀이고 일부 악세서리 일경우 단위 ml로 변경
    isCustom?: boolean;
}

export default class ManufactureItem extends Validatable {
    @observable itemName?: string;
    @observable unit?: 'rxn' | 'ml' | '';
    @observable qty?: number;
    @observable pooling: number | null;
    @observable info?: string;
    @observable probeID?: string;
    @observable remark?: string;
    @observable lotNO?: string;
    @observable catNO?: string;
    @observable expDate?: Date;
    @observable storageTemp?: '-80℃' | '-20℃' | '4℃' | 'RT' | 'RT (-80℃)' | storageTempKeyType;
    @observable shippingCondition?: 'RT' | 'Ice pack' | 'Dry ice' | '4~8℃' | '';
    @observable boxNO?: string;
    @observable no?: number;
    @observable numberPerQty?: number | null;
    @observable unitQty?: number; // 미사용
    @observable validateList?: string[];
    @observable panelName?: string;
    @observable hybRxn?: number | null;
    @observable version?: ItemVersion;
    optionReadonly: boolean;

    // for ui
    @observable isCustom?: boolean;

    // for manufacture structure
    @observable itemOptions: ManufactureItemOption;
    @observable probeIDInputData?: string;

    static readonly ItemNameList = {
        lp: 'LP',
        tc: 'TC',
        cleanUp: 'Clean-up',
        st: 'ST',
        polymerase: 'Polymerase'
    }

    static readonly StorageTempList = {
        m80: '-80℃',
        m20: '-20℃',
        p4: '4℃',
        rt: 'RT',
        rtm80: 'RT (-80℃)'
    }

    static readonly ShippingCoditionList = {
        dryIce: 'Dry ice',
        p48: '4~8℃',
        rt: 'RT',
    }

    static readonly UnitList = {
        // rxn: 'rxn',
        rxn: 'Sample(s)',
        ml: 'ml',
    }

    static readonly HeaderList = {
        itemName: 'Items',
        unit: 'Unit',
        qty: "Q'ty",
        pooling: 'Multiplexing',
        hybRxn: 'Hyb-rxn',
        probeID: 'Probe ID',
        panelName: 'Panel Name',
        remark: 'Remark',
        lotNO: 'Lot No.',
        catNO: 'Cat No.',
        expDate: 'Exp. Date',
        storageTemp: 'Stor. Temp.',
        shippingCondition: 'Ship. Temp',
        boxNO: 'Box No.',
        numberPerQty: "Unit Q'ty"
    }

    static readonly HeaderKeyList = {
        itemName: 'itemName',
        unit: 'unit',
        qty: 'qty',
        pooling: 'pooling',
        hybRxn: 'hybRxn',
        probeID: 'probeID',
        panelName: 'panelName',
        remark: 'remark',
        lotNO: 'lotNO',
        catNO: 'catNO',
        expDate: 'expDate',
        storageTemp: 'storageTemp',
        shippingCondition: 'shippingCondition',
        boxNO: 'boxNO',
        numberPerQty: 'numberPerQty',
    }

    constructor(props?: ManufactureItemProps) {
        super();
        const readonly = props?.optionReadonly ? true : false
        this.itemOptions = new ManufactureItemOption(readonly);
        this.pooling = null;
        // this.qty = null;
        this.itemName = '';
        this.unit = 'rxn';
        this.info = '';
        this.remark = '';
        this.lotNO = '';
        this.catNO = '';
        this.storageTemp = '';
        this.shippingCondition = '';
        this.boxNO = '';
        this.panelName = '';
        this.validateList = [];
        this.optionReadonly = false;
        this.numberPerQty = null;
        this.version = null;
        this.isCustom = false;
        Object.assign(this, props);
        if (!readonly) {
            this.initErrorsRecursive();
        }
    }

    needValidate = (key: string): boolean => {
        return this.validateList.filter((v) => {
            return v === key;
        }).length > 0;
    }

    setOptionRXN = reaction(
        () => this.numberPerQty,
        (val) => {
            if (val) {
                this.itemOptions.rxn = val
            }
        }
    )
    setOptionProbeID = reaction(
        () => this.probeID,
        (val) => {
            this.itemOptions.probeID = val
        }
    )
    setSelectedUnit = reaction(
        () => this.unit,
        (val) => {
            if (val) {
                this.itemOptions.selectedUnit = (val as UnitType);
            }
        }
    )
    setHybRxn1 = reaction(
        () => this.numberPerQty,
        (val) => {
            if ((this.pooling !== null && this.pooling !== undefined) && this.pooling !== 0) {
                this.hybRxn = (val / this.pooling)
            }
        }
    )
    setHybRxn2 = reaction(
        () => this.pooling,
        (val) => {
            if ((this.numberPerQty !== null && this.numberPerQty !== undefined) && val !== 0) {
                this.hybRxn = (this.numberPerQty / val)
            }
        }
    )

    checkItemName = reaction(
        () => this.itemName,
        (val) => {
            if (this.isCustom) {
                if (
                    val.toLowerCase() === 'clean-up bead'
                    || val.toLocaleLowerCase() === 'streptavidin bead'
                    || val.toLocaleLowerCase() === 'polymerase'
                ) {
                    this.unit = 'ml';
                }
            }
        }
    )

    padString = (str: string, pad: string = '0', times: number = 1, position: 'left' | 'right' = 'left') => {
        let result = '';
        for (let i = 0; times > i; i++) {
            result += pad;
        }
        if (position === 'left') {
            return `${result}${str}`;
        }
        else {
            return `${str}${result}`;
        }
    }

    padZeroCatNORxn = (catNO: string | null) => {
        const reg = /^.+[-]{1}[0-9]{1}\D?$/;
        if (catNO === null || catNO === undefined) {
            return null;
        }
        if (catNO.match(reg)) {
            const str = catNO.split('-');
            const digit = this.padString(str[str.length - 1], '0', 1, 'left');
            str[str.length - 1] = digit;
            return str.join('-');
        }
        else return catNO;
    }

    get manipulatedCatNO(): string | null {
        const str = this.catNO.split('-');
        const boxType = str[0];
        let rxnStr = str[str.length - 1];
        if (this.itemOptions.version === 'v3') {
            if (this.itemOptions.itemType === 'tc1'
                || this.itemOptions.itemType === 'tc2'
                || this.itemOptions.itemType === 'tc3') {
                const hybRxn = this.hybRxn ? this.hybRxn.toString() : this.numberPerQty.toString();
                const plate = rxnStr.match(/[^0-9]/g) ?? [];
                str[str.length - 1] = this.padString(hybRxn, '0', (2 - hybRxn.length), 'left') + plate.join('');
                return str.join('-');
            }
            if (this.unit === 'ml') {
                if (boxType === 'CMCB') {
                    // 10x
                    rxnStr = Math.floor(parseFloat(rxnStr) * 10).toString();
                    str[str.length - 1] = this.padString(rxnStr, '0', (3 - rxnStr.length), 'left');
                    return str.join('-');
                }
                else if (boxType === 'CMSB' || boxType === 'CMPF') {
                    // 100x
                    rxnStr = Math.floor(parseFloat(rxnStr) * 100).toString();
                    if (rxnStr.length > 3) {
                        if (rxnStr === '1000') {
                            str[str.length - 1] = '00A';
                        }
                        else {
                            str[str.length - 1] = 'XXX';
                        }
                    }
                    else {
                        str[str.length - 1] = this.padString(rxnStr, '0', (3 - rxnStr.length), 'left');
                    }
                    return str.join('-');
                }
                return this.padZeroCatNORxn(this.catNO);
            }
            return this.padZeroCatNORxn(this.catNO);
        }
        else {
            return this.padZeroCatNORxn(this.catNO);
        }
    }

    syncFromItemOptions() {
        const item = this.itemOptions.getItemBox();
        if (item.version === null) {
            this.catNO = '';
            this.storageTemp = item.storageTemp;
            return;
        }
        if (item.isCatNoHasQuestion()) {
            this.catNO = '';
        }
        else if (item.catNo === null || item.catNo === undefined) {
            this.catNO = '';
        }
        else {
            this.catNO = item.catNo;
        }
        this.storageTemp = item.storageTemp;
    }

    // override
    validateSelf(): void {
        if (this.needValidate('itemName')) {
            if (!this.itemName) {
                this.setErrorTextByName('itemName', Validatable.errorTexts.needValue);
            }
        }
        if (this.needValidate('unit')) {
            if (!this.unit) {
                this.setErrorTextByName('unit', Validatable.errorTexts.needValue);
            }
        }
        if (this.needValidate('qty')) {
            if (!this.qty) {
                this.setErrorTextByName('qty', Validatable.errorTexts.needValue);
            }
            else if (this.qty < 0) {
                this.setErrorTextByName('qty', Validatable.errorTexts.positive);
            }
        }
        if (this.itemName === 'TC #3') {
            if (!this.probeID) {
                this.setErrorTextByName('probeID', Validatable.errorTexts.needValue);
            }
            else if (!Helper.checkRegex(this.probeID, /^TBD.*/)) {
                if (!Helper.checkRegex(this.probeID, probeReg)) {
                    this.setErrorTextByName('probeID', `${Validatable.errorTexts.badFormat} -> 포맷: '(YYMM or CLMX)PD###-V#' 또는 '(YYMM or CLMX)###-V##' 또는 'TBD' 또는 'TBD + 문자열(한글,영문,숫자,특수문자)'`);
                }
            }
        }
        if (this.itemName === 'TC#3') {
            if (!this.probeID) {
                this.setErrorTextByName('probeID', Validatable.errorTexts.needValue);
            }
            else if (!Helper.checkRegex(this.probeID, /^TBD.*/)) {
                if (!Helper.checkRegex(this.probeID, probeReg)) {
                    this.setErrorTextByName('probeID', `${Validatable.errorTexts.badFormat} -> 포맷: '(YYMM or CLMX)PD###-V#' 또는 '(YYMM or CLMX)###-V##' 또는 'TBD' 또는 'TBD + 문자열(한글,영문,숫자,특수문자)'`);
                }
            }
        }
        if (this.needValidate('lotNO')) {
            if (!this.lotNO) {
                this.setErrorTextByName('lotNO', Validatable.errorTexts.needValue);
            }

        }
        if (this.needValidate('catNO')) {
            if (!this.catNO) {
                this.setErrorTextByName('catNO', Validatable.errorTexts.needValue);
            }

        }
        if (this.needValidate('expDate')) {
            if (!this.expDate) {
                this.setErrorTextByName('expDate', Validatable.errorTexts.needValue);
            }
            else if (dayjs(this.expDate).diff(new Date(), 'month') < 0) {
                this.setErrorTextByName('expDate', '잘못된 날짜');
            }

        }
        if (this.needValidate('storageTemp')) {
            if (!this.storageTemp) {
                this.setErrorTextByName('storageTemp', Validatable.errorTexts.needValue);
            }

        }
        if (this.needValidate('shippingCondition')) {
            if (!this.shippingCondition) {
                this.setErrorTextByName('shippingCondition', Validatable.errorTexts.needValue);
            }

        }
        if (this.needValidate('boxNO')) {
            if (!this.boxNO) {
                this.setErrorTextByName('boxNO', Validatable.errorTexts.needValue);
            }

        }
        if (this.needValidate('numberPerQty')) {
            if (!this.numberPerQty) {
                this.setErrorTextByName('numberPerQty', Validatable.errorTexts.needValue);
            }
            else if (this.numberPerQty < 0) {
                this.setErrorTextByName('numberPerQty', Validatable.errorTexts.positive);
            }
        }
        if (this.itemName === 'TC #3' && this.probeID && Helper.checkRegex(this.probeID, /^TBD.*/)) {
            if (!this.probeIDInputData) {
                this.setErrorTextByName('probeIDInputData', Validatable.errorTexts.needValue);
            }
            else if (!Helper.checkRegex(this.probeIDInputData, /^TBD.*/)) {
                if (!Helper.checkRegex(this.probeIDInputData, probeReg)) {
                    this.setErrorTextByName('probeIDInputData', `${Validatable.errorTexts.badFormat} -> 포맷: '(YYMM or CLMX)PD###-V#' 또는 '(YYMM or CLMX)###-V##' 또는 'TBD' 또는 'TBD + 문자열(한글,영문,숫자,특수문자)'`);
                }
            }
        }
        if (this.itemName === 'TC#3' && this.probeID && Helper.checkRegex(this.probeID, /^TBD.*/)) {
            if (!this.probeIDInputData) {
                this.setErrorTextByName('probeIDInputData', Validatable.errorTexts.needValue);
            }
            else if (!Helper.checkRegex(this.probeIDInputData, /^TBD.*/)) {
                const reg = /^(([0-9][0-9](0[1-9]|1[0-2]))|(CLMX))PD[0-9]{3}-V([0-9]|[0-9][0-9])$/
                console.log(this.probeIDInputData.match(reg));
                if (!Helper.checkRegex(this.probeIDInputData, probeReg)) {
                    this.setErrorTextByName('probeIDInputData', `${Validatable.errorTexts.badFormat} -> 포맷: '(YYMM or CLMX)PD###-V#' 또는 '(YYMM or CLMX)###-V##' 또는 'TBD' 또는 'TBD + 문자열(한글,영문,숫자,특수문자)'`);
                }
            }
        }
        if (this.itemName.toLowerCase() === 'clean-up bead'
            || this.itemName.toLocaleLowerCase() === 'streptavidin bead'
            || this.itemName.toLocaleLowerCase() === 'polymerase') {
            if (this.isCustom) {
                if (this.unit !== 'ml') {
                    this.setErrorTextByName('unit', `해당 아이템의 단위는 'ml' 만 가능합니다.`);
                }
            }
        }
    }
}